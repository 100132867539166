<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">所有账号客户汇总</span>
      <download-excel
        class="export-excel-wrapper"
        v-if="tabledata && tabledata.length != 0"
        :data="tabledata"
        :fields="json_fields"
        name="所有账号客户汇总.xls"
      >
        <el-button type="text" v-if="tabledata && tabledata.length != 0"
          >导出excel表格</el-button
        >
      </download-excel>
    </div>
    <div class="content">
      <div class="sum_number">
        <el-descriptions border class="summary" size="small" :column="3">
          <el-descriptions-item label="客户总数" width="16.7%">{{
            totalCustomNumeber
          }}</el-descriptions-item>
          <el-descriptions-item label="直播快当前资源总数" width="16.7%">{{
            totalPathNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="云手机当前资源总数" width="16.7%">{{
            totalPhoneNumber
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="search-line">
        <el-input
          class="search-input"
          v-model="searchQuery"
          size="mini"
          placeholder="请输入搜索文本"
          style="width: 250px"
          @keyup.enter.native="handleSearch"
          clearable
        />
        <el-button
          class="search-input"
          size="mini"
          type="primary"
          @click="handleSearch"
        >
          搜索
        </el-button>
        <div class="search-tips">
          支持公司名/手机号模糊搜索，无觅ID/关联ID精确搜索
        </div>

        <el-button
          class="update-item"
          size="mini"
          type="primary"
          @click="showUpdateInviter"
        >
          统一更新销售
        </el-button>
      </div>
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="channelmainaccount"
        :pagination="pagination"
        tableheight="calc(100vh - 350px)"
        :data="tabledata"
      >
      </com-table>
    </div>
    <models
      :showmodel="setInviterModel"
      title="更新销售"
      @confirm="setInviter"
      @closemodel="closeSetInviter"
      class="invitermodel"
    >
      <div class="maincontent">
        <el-row>
          <el-col :span="5">
            <div class="labelname"><span>销售：</span></div>
          </el-col>
          <el-col :span="19">
            <el-select
              size="small"
              v-model="curInviter"
              filterable
              placeholder="请选择分组"
            >
              <el-option
                v-for="item in inviters"
                :key="item.UserId"
                :label="item.Username"
                :value="item.UserId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </models>
    <models
      :showmodel="updateInviterModel"
      title="统一更新销售"
      @confirm="updateInviter"
      @closemodel="closeUpdateInviter"
      class="updateinvitermodel"
    >
      <div class="maincontent">
        <el-row>
          <el-col :span="5">
            <div class="labelname"><span>老销售：</span></div>
          </el-col>
          <el-col :span="19">
            <el-select
              size="small"
              v-model="oldInviter"
              filterable
              placeholder="请选择分组"
            >
              <el-option
                v-for="item in inviters"
                :key="item.UserId"
                :label="item.Username"
                :value="item.UserId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <br />
        <el-row>
          <el-col :span="5">
            <div class="labelname"><span>新销售：</span></div>
          </el-col>
          <el-col :span="19">
            <el-select
              size="small"
              v-model="newInviter"
              filterable
              placeholder="请选择分组"
            >
              <el-option
                v-for="item in inviters"
                :key="item.UserId"
                :label="item.Username"
                :value="item.UserId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </models>

    <edit-company
      title="公司名称"
      :row="row"
      :showeditcompany="showeditcompany"
      @closeeditcompany="closeeditcompany"
    />
    <edit-channel-outer-id
      title="关联ID"
      :row="row"
      :showeditchannelouterid="showeditchannelouterid"
      @closeeditchannelouterid="closeeditchannelouterid"
    />
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { channelmainaccount } from "@/utils/tableList";
import Models from "@/components/Models/index.vue";
import EditCompany from "./Models/EditCompany";
import EditChannelOuterId from "../ChannelSubAccount/Models/EditChannelOuterId.vue";

export default {
  name: "channelmainaccount",
  data() {
    return {
      channelmainaccount,
      searchQuery: "",
      json_fields: {
        公司名称: {
          callback: (value) => {
            return value.CompanyName || "-";
          },
        },
        无觅ID: "UserId",
        无觅手机号: "PhoneNumber",
        直播快当前资源数: {
          callback: (value) => {
            return value.PathCurrentCount || "0";
          },
        },
        直播快折扣: {
          callback: (value) => {
            return value.PathDiscount || "1.0";
          },
        },
        云手机当前资源数: {
          callback: (value) => {
            return value.PhoneCurrentCount || "0";
          },
        },
        云手机折扣: {
          callback: (value) => {
            return value.PhoneDiscount || "1.0";
          },
        },
        路由器当前资源数: {
          callback: (value) => {
            return value.RouterCount || "0";
          },
        },
        销售: "Saler",
        关联ID: {
          callback: (value) => {
            return value.ChannelOuterId || "-";
          },
        },
        邀请人: {
          callback: (value) => {
            return value.Inviter || "-";
          },
        },
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      tabledata: [],
      tabledataAll: [],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      row: {},
      setInviterModel: false,
      updateInviterModel: false,
      inviters: [],
      curInviter: "",
      oldInviter: "",
      newInviter: "",
      showeditcompany: false,
      showeditchannelouterid: false,
      totalCustomNumeber: 0,
      totalPathNumber: 0,
      totalPhoneNumber: 0,
    };
  },
  components: {
    ComTable,
    Models,
    EditCompany,
    EditChannelOuterId,
  },
  mounted() {
    this.getChannelMain();
    this.getsubaccountlist();
    window.showSetInviter = this.showSetInviter;
    window.editChannelCompany = this.editChannelCompany;
    window.editChannelOuterId = this.editChannelOuterId;
  },
  methods: {
    getChannelMain() {
      this.$store.dispatch("user/channelmainlist").then((res) => {
        if (res.RetCode == 0) {
          this.tabledata = res.List;
          this.tabledataAll = res.List;
          this.totalCustomNumeber = this.tabledata.length;
          for (let i = 0; i < this.tabledata.length; i++) {
            this.totalPathNumber =
              this.totalPathNumber + this.tabledata[i].PathCurrentCount;
            this.totalPhoneNumber =
              this.totalPhoneNumber + this.tabledata[i].PhoneCurrentCount;
          }
        }
      });
    },
    handleSearch() {
      if (this.searchQuery == "") {
        this.tabledata = this.tabledataAll;
      } else {
        let tmp = [];
        for (const i of this.tabledataAll) {
          if (i.PhoneNumber.indexOf(this.searchQuery) != -1) {
            tmp.push(i);
          } else if (i.CompanyName.indexOf(this.searchQuery) != -1) {
            tmp.push(i);
          } else if (i.UserId == this.searchQuery) {
            tmp.push(i);
          } else if (i.ChannelOuterId == this.searchQuery) {
            tmp.push(i);
          }
        }
        this.tabledata = tmp;
      }
    },

    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        // console.log("get user info: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          let Username;
          if (res.NickName.length == 0) {
            Username = res.Username;
          } else {
            Username = res.NickName;
          }
          let newList = {
            UserId: res.UserId,
            UserType: res.UserType,
            Username: Username,
          };
          this.inviters.push(newList);
        }
      });
    },
    getsubaccountlist() {
      this.$store.dispatch("sub/getsublist").then((res) => {
        if (res.RetCode == 0) {
          this.inviters = res.UserInfos;
        }
        this.getuserinfo();
      });
    },
    showSetInviter(row) {
      this.row = row;
      this.curInviter = row.SalerId;
      this.setInviterModel = true;
    },
    closeSetInviter() {
      this.setInviterModel = false;
    },
    setInviter() {
      this.$store
        .dispatch("user/setChannelInviter", {
          UserId: this.row.UserId,
          Inviter: this.curInviter,
        })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("修改成功");
            this.getChannelMain();
            this.closeSetInviter();
          } else {
            this.$message.error("修改失败");
            this.closeSetInviter();
          }
        });
    },
    showUpdateInviter() {
      this.updateInviterModel = true;
    },
    closeUpdateInviter() {
      this.updateInviterModel = false;
    },

    updateInviter() {
      this.$store
        .dispatch("user/updateChannelInviters", {
          OldInviter: this.oldInviter,
          NewInviter: this.newInviter,
        })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("修改成功");
            this.getChannelMain();
            this.closeUpdateInviter();
          } else {
            this.$message.error("修改失败");
            this.closeUpdateInviter();
          }
        });
    },
    editChannelCompany(row) {
      this.row = row;
      this.showeditcompany = !this.showeditcompany;
    },
    closeeditcompany(row) {
      this.showeditcompany = !this.showeditcompany;
      this.getChannelMain();
    },
    editChannelOuterId(row) {
      this.row = row;
      this.showeditchannelouterid = !this.showeditchannelouterid;
    },
    closeeditchannelouterid() {
      this.showeditchannelouterid = !this.showeditchannelouterid;
      this.getChannelMain();
    },
    
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>